import React from 'react';
import EventEntry from '../EventEntry';

const DayEntry = ({ details }) => {
  return (
    <div className="day-entry">
      <h3>{details.name}</h3>
      {details.events.map((e) => (
        <EventEntry details={e.event} />
      ))}
    </div>
  );
};

export default DayEntry;
