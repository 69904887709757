/* eslint-disable no-use-before-define */
import { useLazyQuery } from 'react-apollo';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import Spinner from '../components/layout/Spinner';
import bg from '../images/festival/bg.jpg';
import DayEntry from '../components/festival-schedule/DayEntry';

const FestivalSchedule = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState();

  const [fetchData] = useLazyQuery(GET_SUBPAGE_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const configs = [...data.festivalSchedules.nodes];
      const latestConfig = configs.reduce((prev, current) => {
        return new Date(prev.date) > new Date(current.date) ? prev : current;
      });
      setConfig(latestConfig.schedule);
      setIsLoading(false);
    },
  });

  const spinnerWrapper = (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        zIndex: 9999,
        position: 'relative',
      }}
    >
      <Spinner />
    </div>
  );

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <Layout isFestival>
      <SEO title="Festiwal - harmonogram" />
      <div className="festival full">
        <img src={bg} alt="background" />
        <div className="color-layer" />
        {isLoading || !config ? (
          spinnerWrapper
        ) : (
          <>
            <div className="festival-schedule animate-left">
              <Link to="/festival">&larr; Powrót do strony festiwalu</Link>
              <h1>Program festiwalu</h1>
              <div className="labels">
                <p>Wydarzenie</p>
                <p>Czas</p>
                <p>Gdzie</p>
              </div>
              {config.days.map((c) => (
                <DayEntry details={c.day} />
              ))}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default FestivalSchedule;

const GET_SUBPAGE_DATA = gql`
  query GetSubpageData {
    festivalSchedules {
      nodes {
        date
        schedule {
          days {
            day {
              name
              events {
                event {
                  duration
                  location
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
