import React from 'react';

const EventEntry = ({ details }) => {
  return (
    <div className="event-entry">
      <p>{details.name}</p>
      <p>{details.duration}</p>
      <p>{details.location}</p>
    </div>
  );
};

export default EventEntry;
